import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast, { Toaster } from 'react-hot-toast';

function Home() {
  const cryptoWallets = [
    { name: 'BITCOIN', address: 'bc1qrhdcy3vl2qkjjq3ufc0u70vtun4f7yfavcl6x9', uri: `bitcoin:bc1qrhdcy3vl2qkjjq3ufc0u70vtun4f7yfavcl6x9` },
    { name: 'LTC', address: 'LaGrw1RgSWnhxKo1SiG9D9n4tVTVJyL2VC', uri: `litecoin:LaGrw1RgSWnhxKo1SiG9D9n4tVTVJyL2VC` },
    { name: 'USDT', address: '0x2cc4363765AFBe8dFE787754Ecc648A8fc4316F3', uri: `usdt:0x2cc4363765AFBe8dFE787754Ecc648A8fc4316F3` },
    { name: 'ETHEREUM', address: '0x2cc4363765AFBe8dFE787754Ecc648A8fc4316F3', uri: 'ethereum:0x2cc4363765AFBe8dFE787754Ecc648A8fc4316F3' },
  ];

  const notify = (walletName) => {
    toast.success(`${walletName} address copied to clipboard!`, {
      duration: 3000,
      position: 'top-center',
      style: {
        border: '1px solid #4CAF50',
        padding: '8px',
        color: '#4CAF50',
        backgroundColor: '#1a1a1a',
      },
      iconTheme: {
        primary: '#4CAF50',
        secondary: '#FFFFFF',
      },
    });
  };

  return (
    <div className="container mx-auto p-6 min-h-screen flex flex-col items-center justify-center bg-gray-900 text-gray-100">
      <h2 className="text-4xl font-bold mb-6 text-center text-primary">Support Me with Crypto</h2>
      <p className="text-lg text-center mb-8 text-gray-400">
        You can support me by sending cryptocurrency. Thank you for your generosity!
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full max-w-4xl shadow-lg">
        {cryptoWallets.map((wallet, index) => (
          <div
            key={index}
            className="card bg-gray-800 text-gray-100 shadow-xl rounded-lg"
          >
            <div className="card-body flex flex-col items-center p-6">
              <h3 className="card-title text-2xl font-semibold mb-4">{wallet.name}</h3>
              <QRCodeCanvas
                value={wallet.uri}
                size={128}
                bgColor="#1a1a1a"
                fgColor="#ffffff"
                className="mb-4 p-2 bg-gray-700 rounded-lg"
              />
              <p className="text-sm break-all mb-4 text-center bg-gray-800 p-2 rounded-md text-gray-400">
                {wallet.address}
              </p>

              <CopyToClipboard text={wallet.address} onCopy={() => notify(wallet.name)}>
                <button className="btn btn-outline btn-primary w-full transition-all duration-200 no-animation hover:bg-primary hover:border-primary hover:text-white">
                  Copy {wallet.name} Address
                </button>
              </CopyToClipboard>
            </div>
          </div>
        ))}
      </div>

      {/* Footer Section */}
      <footer className="mt-12 w-full text-center border-t border-gray-700 pt-6 pb-4 text-gray-400">
        <p className="mb-2">
          Join our <a className="font-bold hover:underline text-blue-600" href="https://discord.gg/sqVBrMVQmp">Discord Server</a> and share the screenshot of the transaction to get a special Role.
        </p>
        <p className="mb-2">
          Or support me on <a className="font-bold hover:underline text-pink-500" href="https://www.patreon.com/NotNT77" target="_blank" rel="noopener noreferrer">Patreon</a>.
        </p>
        <p>© 2024 NotReal003. All rights reserved.</p>
      </footer>

      {/* Toast Container */}
      <Toaster />
    </div>
  );
}

export default Home;
