import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './pages/Home';
import ThankYou from './pages/ThankYou';
import RedirectComponent from './components/RedirectComponent';

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-gradient-to-r from-gray-700 via-gray-900 to-black text-white">
        <header className="p-6 text-center text-2xl font-bold">
          Pay | NotReal003
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<RedirectComponent to="/" />} />
          <Route path="/thank-you" element={<ThankYou />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
