import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectComponent = ({ to }) => {
  const navigate = useNavigate();

  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null;
};

export default RedirectComponent;